<template>
  <u-popover overlay :ui="popoverConfig" v-model:open="open">

    <!--button-->
    <u-tooltip :text="currentUser.name" :popper="{placement: 'left'}">
      <div class="flex items-center cursor-pointer relative">
        <user-avatar
          :user="currentUser"
          :chip-color="newCount ? 'red' : null"
          chip-position="top-right"
          size="lg"
          class="hover:ring-4 hover:ring-gray-300 ring-offset-2 transition-all"
        />
      </div>
    </u-tooltip>

    <template #panel="{close}">
      <layout-profile-navigation :close-function="close" />
    </template>

  </u-popover>

</template>

<script setup>
  const sessionStore = useSessionStore();
  const {currentUser} = storeToRefs(sessionStore);

  const notificationsStore = useNotificationStore();
  const {newCount} = storeToRefs(notificationsStore);
  const open = ref(false);

  const popoverConfig = {
    wrapper: 'flex',
    width:'w-[375px]',
    container: 'z-[56]',
    base: '-mr-[2px] -mt-[74px]',
    transition: {
      enterFromClass: 'translate-y-0',
      leaveToClass: 'translate-y-0'
    }
  };
</script>
