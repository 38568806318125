<template>
  <u-popover overlay :ui="{width:'w-[250px]', base: 'p-4 mr-2'}">
    <div class="w-16 flex items-center flex-col cursor-pointer hover:border-charcoal-200 border-2 border-transparent transition-colors rounded pt-2 pb-0" role="button">
      <div class="relative flex-inline text-charcoal leading-none">
        <u-icon name="i-ri-hard-drive-2-line" class="w-7 h-7 leading-none" />
        <u-icon :name="currentUser.isStorageFull ? 'i-ri-error-warning-fill' : 'i-ri-add-circle-fill'" class="w-4 h-4 absolute -top-1.5 -right-1 z-10 " :class="iconColor" />
        <div class="absolute -top-1.5 -right-1 z-1 bg-white w-4 h-4 rounded-full"></div>
      </div>

      <core-storage-meter class="mb-1 mt-0.5 w-3/4" />

      <p class="font-semibold text-lg text-charcoal leading-none" :class="currentUser.isStorageFull ? 'text-red' : ''">{{currentUser.storagePercentUsed}}<span class="text-sm font-normal pl-px">%</span></p>
    </div>

    <template #panel="{close}">
      <p class="flex gap-2 font-semibold mb-5">
        <u-icon name="i-ri-hard-drive-2-line" class="text-3xl" />
        <span class="text-xl">Storage</span>
      </p>
      <p class="text-base text-text-light leading-none">{{currentUser.files_count}} files preserved</p>
      <core-storage-meter class="mt-1 mb-2" height="h-2.5" />
      <p class="text-base text-text-light leading-none"><span class="font-semibold">{{currentUser.storage_remaining_gb}}</span> free of {{currentUser.storage_capacity_gb}}</p>

      <u-button
        variant="outline"
        color="charcoal"
        :leading-icon="COMMON_ICONS.add"
        @click="close"
        size="sm"
        block
        :to="EXTERNAL_URLS.storage"
        :external="true"
        class="no-underline mt-5"
        label="Add Storage"
      />

    </template>
  </u-popover>
</template>

<script setup>
  const sessionStore = useSessionStore();
  const {currentUser} = storeToRefs(sessionStore);

  const iconColor = computed(() => {
    const level = sessionStore.currentUser.capacityLevel;
    const colors = {
      red: 'text-red-400',
      orange: 'text-orange-500',
      green: 'text-charcoal'
    };

    return colors[level];
  });
</script>
