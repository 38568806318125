<template>
  <div class="flex flex-col h-full">
    <div class="py-4 px-3">
      <div class="flex gap-2 items-center mb-4 py-2 border-b border-b-gray-200">
        <u-button
          @click="toPeople"
          variant="soft"
          icon="i-ri-arrow-left-line"
          color="charcoal"
          size="xs"
          title="Back to People"
        />
        <h3 class="font-serif text-xl flex-1">People</h3>
      </div>

      <label class="flex items-center mb-2">
        <span class="text-xs flex-1 text-gray-500">Show Cover Photos</span>
        <u-toggle v-model="showAvatars" size="xs" />
      </label>

      <core-search-input
        placeholder="Search People"
        @search="updateSearchQuery"
        @clear="updateSearchQuery({q: ''})"
        :loading="pending"
      />
    </div>

    <div class="flex-1 scrollbar-light overflow-y-auto -mr-4 pr-2 firefox:pr-4 scrollbar-gutter-stable" ref="listEl">
      <transition-group name="f-slide-fade">
        <core-contextual-loading-box v-if="pending" size="xs" key="loading" :loading-text="`${searchTerm ? 'Searching' : 'Loading'} People`" />
        <core-contextual-error-box v-else-if="error" size="xs" key="error" :action="refresh" />
        <core-empty-state
          v-else-if="searchTerm && !people.length"
          heading="No Results"
          description="We couldn't find any people matching your search."
          :icon="COMMON_ICONS.person"
          size="xs"
        />

        <core-list v-else :items="people" :scroller-elem="listEl" :disable-approach-bottom="pending || isGettingMore || !hasMore" @approach-bottom="getMore" container-class="grid" grid-gap-class="gap-0" item-class="min-w-0">
          <template #default="{ item: person }">
            <nuxt-link active-class="font-semibold bg-shell-200 text-heroblue" class="leading-none text-charcoal no-underline hover:bg-shell-200 py-1.5 px-4 flex gap-2 items-center" :to="useBuildRoute().toPerson({person})" :data-person-id="person.id">
              <people-avatar v-if="showAvatars" size="md" :person="person"/>
              <div class="flex-1 flex flex-col min-w-0">
                <div v-if="person.name" class="flex gap-2">
                  <span class="text-sm leading-none break-long-string" v-if="person.name">{{person.name}}</span>
                </div>
                <span class="text-sm italic text-gray-400" v-else>Unnamed</span>
                <span class="text-xs text-charcoal-400 font-normal" v-if="person.yearDateDisplay">{{person.yearDateDisplay}}</span>
              </div>
              <span class="text-gray-400 text-2xs">{{person.files_count}}</span>
            </nuxt-link>
          </template>
        </core-list>
      </transition-group>
    </div>
  </div>
</template>

<script setup>
  import {useStorage} from '@vueuse/core';

  const listEl = ref();
  const showAvatars = useStorage(
    'core-ui-people-context-nav-show-avatars',
    true
  );

  //search
  const searchTerm = ref('');
  function updateSearchQuery({q}) {
    searchTerm.value = q;
  }

  function toPeople() {
    navigateTo(useBuildRoute().toPeople());
  }

  const isGettingMore = ref(false);
  async function getMore() {
    if (!hasMore.value) {
      return;
    }

    try {
      isGettingMore.value = true;
      await peopleStore.getPeople({nextPage: true});
    } catch (e) {
      useErrorToast().add('There was an issue getting more people.');
    } finally {
      isGettingMore.value = false;
    }
  }

  const peopleStore = usePeopleStore();
  const {people, person, hasMore} = storeToRefs(peopleStore);
  const {refresh, pending, error} = await useAsyncData(
    'peopleNav',
    async () => {
      await peopleStore.getPeople({
        search: searchTerm.value,
        order: PEOPLE_SORT_OPTIONS[0].param
      });

      if (!searchTerm.value) {
        while (!people.value?.find(p => p.id === person.value.id)) {
          await getMore();
        }
      }
    },
    {
      watch: [searchTerm],
      server: false
    }
  );

  async function scrollToCurrentPerson() {
    //note: for this to work as structured, the album must be loaded before this component is mounted, thus the useAsyncData above
    await nextTick();
    if (listEl.value) {
      const personEl = listEl.value.querySelector(`[data-person-id="${person.value.id}"]`);
      if (personEl) {
        personEl.scrollIntoView({behavior: 'instant', block: 'center'});
      }
    }
  }

  useEventBus(PEOPLE_EVENTS.navigationRefresh).on(() => {
    resetNavigation();
  });

  async function resetNavigation() {
    //reset search
    searchTerm.value = '';

    //reset top level
    await refresh();

    //scroll to current element
    scrollToCurrentPerson();
  }

  onMounted(async () => {
    await waitFor(10);
    scrollToCurrentPerson();
  });

</script>
